import { React, useState, useEffect } from "react";

import backgroundPattern from "../../Assets/background-pattern.png";

import SeccondaryHeader from "../secondaryHeader";
import Footer from "../Footer";

function Terms() {
	// This is the page title
	const [title] = useState(
		"Term's and Conditions | Wonderland Treks & Tours Pvt. Ltd."
	);

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
	return (
		<div
			style={{ backgroundImage: `url(${backgroundPattern})` }} // Background pattern image
			className="bg-ColorPrimary text-White bg-repeat"
		>
			<SeccondaryHeader />
			<main>
				<div className="md:mx-20 text-Brandwhite md:border-x md:border-Brandwhite">
					<h1 className=" py-10 md:py-12 lg:py-14 px-5 md:px-24 | text-xl md:text-2xl lg:text-3xl font-extrabold text-center font-serif">
						Term's and Conditions
					</h1>

					<div className=" bg-ColorPrimary | px-8 pt-5 pb-20 | border-t border-White">
						<div className=" my-5">
							<h2 className=" text-sm md:text-xl lg:text-3xl font-serif">
								Booking Terms And Conditions:{" "}
								<span className=" font-bold">(Please read carefully)</span>
							</h2>
							<p className=" mt-4 | text-sm md:text-base text-justify">
								All booking for the Tours /Treks are made with Wonderland Treks
								& Tours Private Limited, herein after referred to as ' the
								company' and are subject to the following conditions: The person
								signing the booking form accepts these conditions and is
								hereafter referred to as the client.
							</p>
						</div>

						<div className=" my-7">
							<h2 className=" text-sm md:text-xl lg:text-3xl font-serif">
								Booking:{" "}
							</h2>
							<p className=" mt-2 | text-sm md:text-base  text-justify">
								To book a trip complete and sign the booking form and post to
								the company with your deposit of US$ 200 (non- refundable) US $
								350 (For Booking of tours outside India ). It is strongly
								advised that clients take out insurance to cover cancellation
								charges.
							</p>
						</div>

						<div className=" my-7">
							<h2 className=" text-sm md:text-xl lg:text-3xl font-serif">
								Cancellation:{" "}
							</h2>
							<p className=" mt-2 | text-sm md:text-base  text-justify">
								If circumstances make you cancel your tour, the cancellation
								must be intimated to us in writing. As per the Booking
								Conditions of the tour we are constrained to levy following
								cancellation charges. The cancellation must be intimated in
								writing at the registered/Corporate office of the company duly
								signed by each of the tour participants who signed the Booking
								form. The computation of the period of Notice of Cancellation
								shall be commence only from the time the written request reached
								the Company office by email before working hours.
							</p>
							<p className=" mt-2 | text-sm md:text-base  text-justify">
								Cancellation charges will be as per the agreement at the time of
								booking of the tour.
							</p>

							<ol className="  pl-6 mt-4 | text-sm md:text-base text-justify | space-y-4 list-decimal">
								<li>
									It shall be the duty of client to inform the company in case
									the client has any Medical Condition that may affect his
									ability to enjoy and peruse fully the tour Arrangements and
									where in the Interest of the group or any member there of
									prejudicially affected.
								</li>
								<li>
									The company reserves the right to ask the client to provide
									written Medical certification of his fitness before starting
									the tour/Trek. In the event the Medical Condition is not
									disclosed the company will not be liable to provide any
									assistance or money back.
								</li>
								<li>
									The company makes it a condition that client is adequately
									covered by life, property and Medical Insurance Policy,
									through its auspices, without which the client will not be
									able to participate on the tour. The tour cost does not
									include the Insurance premium and the clients will have to pay
									additional cost for it.
								</li>
								<li>
									The company cannot be made liable for the consequences of
									weather condition, flight cancellations, war or threat of
									civil war, strikes, industrial action, political unrest or any
									other force major, unusual or unforeseen circumstance. The
									company reserves the right to use alternate transport, or vary
									the itinerary if breakdown, sickness or any other force. Any
									additional s that resuikt, such as extra hotel accommodation,
									flight or road transport will be your responsibility and must
									be paid for direct at the time.
								</li>
								<li>
									The company shall in no case and circumstances, what so ever
									be liable to the clients or any person traveling with him
									for:- Any Death, personal Injury, Sickness, Accident, Loss,
									Delay, Discomfort, Increased Expenses, Consequential Loss and
									/ Damage or any kind of theft how so ever caused.
								</li>
								<li>
									The company reserves the right to alter details of the
									services, facilities, tour/ trek and vehicle described in the
									itinerary at any time, when deemed necessary, reasonable and
									advisable.
								</li>
								<li>
									No refund will be made for any unused service included in the
									tour / trek cost. No refund will be payable to any client who
									voluntarily leaves a trip en route.
								</li>
								<li>
									Client agrees to accept the authority and the decision of the
									tour/ trek leader or escort appointed by the company whilst on
									tour with the company. If, in the opinion of the tour/ trek
									leader, any clients behavior is deter mental to the safety,
									welfare or harmonies state of the group as a whole, the client
									may be asked to leave the tour / trek without the right of
									refund.
								</li>
								<li>
									When making your booking it is implied and accepted that you
									have read and understood all these booking conditions and
									agree to abide by them
								</li>
								<li>
									The contract between and any matters arising from it will be
									governed by and construed in accordance with the jurisdiction
									of the law courts where registered office of company is
									located in India.
								</li>
							</ol>
						</div>

						<h2 className=" my-7 | text-sm md:text-xl lg:text-3xl font-serif">
							Our Liabilities & Limitations:{" "}
						</h2>
						<ol className=" pl-6 mt-2 | text-sm md:text-base text-justify | space-y-4 list-disc">
							<li>
								Please note that after the finalization of the Tour/ service
								Cost, if there are any Hike in entrance fees of monuments /
								museums, Taxes, fuel cost or guide charges – by Govt of India,
								the same would be charged as extra.
							</li>
							<li>
								Wonderland Treks & Tours Pvt. Ltd. act only in the capacity of
								agent for the hotels, airlines, transporters, railways &
								contractors providing other services & all exchange orders,
								receipts, contracts & tickets issued by us are issued subject to
								terms & conditions under which these services are provided by
								them.
							</li>
							<li>
								All itineraries are sample itineraries, intended to give you a
								general idea of the likely trip schedule. Numerous factors such
								as weather, road conditions, the physical ability of the
								participants etc. may dictate itinerary changes either before
								the tour or while on the trail. We reserve the right to change
								any schedule in the interest of the trip participants' safety,
								comfort & general well-being.
							</li>
							<li>
								Our rates are based on the prevailing rates as negotiated by us
								with the hotels, Transporters, travel agents & airlines etc.
								Hotels, transporters, travel agents and Airlines retain the
								right to modify the rates without notice. In case of such
								changes the rates quoted before the modification, can be changed
								by us according to the modifications by hotels or airlines. All
								hotel bookings are based on usual check in and check out time of
								the hotels until unless indicated in the itinerary.
							</li>
							<li>
								We shall not be responsible for any delays & alterations in the
								programme or expenses incurred – directly or indirectly – due to
								natural hazards, flight cancellations, accident, breakdown of
								machinery or equipment’s, breakdown of transport, weather,
								sickness, landslides, political closures or any untoward
								incidents.
							</li>
							<li>
								We shall not be responsible for any loss, injury or damage to
								person, property, or otherwise in connection with any
								accommodation, transportation or other services, resulting –
								directly or indirectly – from any act of GOD, dangers, fire,
								accident, breakdown in machinery or equipment, breakdown of
								transport, wars, civil disturbances, strikes, riots, thefts,
								pilferages, epidemics, medical or custom department regulations,
								defaults, or any other causes beyond our control.
							</li>
							<li>
								We do not have any insurance policy covering the expenses for
								accident, sickness, loss due to theft, or any other reasons.
								Visitors are advised to seek such insurance arrangements in
								their home country. All baggage’s & personal property/s at all
								times are at the client's risk.
							</li>
							<li>
								<span className=" font-semibold">Please Note :</span> We will
								not be responsible for any costs arising out of unforeseen
								circumstances like landslides, road blocks, bad weather, etc.
							</li>
						</ol>
					</div>
				</div>
				<section className=" border-t border-White">
					<div className=" md:mx-20 px-5 md:px-24 py-20 | text-center text-Brandwhite | md:border-x md:border-White"></div>
				</section>
			</main>
			<Footer />
		</div>
	);
}

export default Terms;
