import React from "react";

import backgroundPattern from "../Assets/background-pattern.png";

import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import SeccondaryHeader from "./secondaryHeader";
import Footer from "./Footer";

function Destination() {
	const destination = useLocation();
	const currentDestination = [destination.state];

	return (
		<>
			<SeccondaryHeader />
			<main
				style={{ backgroundImage: `url(${backgroundPattern})` }} // Background pattern image
				className=" bg-ColorPrimary text-White | font-serif"
			>
				{currentDestination.map((destination) => {
					return (
						<>
							{/* Render single destination*/}
							<section className=" border-b">
								<div className="md:mx-20 text-center text-White md:border-x md:border-White">
									<h1 className=" py-10 md:py-12 lg:py-14 px-5 md:px-24 | border-b | font-extrabold">
										{destination.name}
									</h1>

									<div className=" bg-ColorPrimary | ">
										<div className=" flex flex-col-reverse lg:flex-row gap-4 md:gap-4 lg:gap-10 | px-4 md:px-8 lg:px-10 py-4 md:py-8 lg:py-12 | text-left">
											<div className=" flex-1">
												<Link
													to="/destinations"
													className="hidden lg:flex items-center | text-sm | group hover:text-ColorSecondary"
												>
													<Icon
														icon="eva:arrow-ios-back-outline"
														// color="white"
														height="20"
													/>
													Back to Destinations
												</Link>
												<h2 className=" pb-4 md:pb-4 lg:pb-8 lg:pt-4 | text-lg md:text-2xl lg:text-3xl font-semibold">
													Country information
												</h2>
												<div className=" pb-8 | text-sm md:text-base font-sans leading-normal text-justify">
													<p className=" pb-2">{destination.discription.p1}</p>
													<p className=" pb-2">{destination.discription.p2}</p>
													<p>{destination.discription.p3}</p>
												</div>
												{/* destination details */}
												<div className=" pb-12 lg:pb-16 | font-sans">
													{[destination.extras].map((extras) => {
														return (
															<div className=" grid gap-y-3">
																<div className=" grid grid-cols-2 gap-4">
																	<strong>
																		{destination.name}'s{" "}
																		{extras.officialName.title} :
																	</strong>
																	<p>{extras.officialName.value}</p>
																</div>
																<div className=" grid grid-cols-2  gap-4">
																	<strong>{extras.population.title} :</strong>
																	<p>{extras.population.value}</p>
																</div>
																<div className=" grid grid-cols-2  gap-4">
																	<strong>{extras.capital.title} :</strong>
																	<p>{extras.capital.value}</p>
																</div>
																<div className=" grid grid-cols-2  gap-4">
																	<strong>{extras.people.title} :</strong>
																	<p>{extras.people.value}</p>
																</div>
																<div className=" grid grid-cols-2  gap-4">
																	<strong>{extras.language.title} :</strong>
																	<p>{extras.language.value}</p>
																</div>
																<div className=" grid grid-cols-2  gap-4">
																	<strong>{extras.currency.title} :</strong>
																	<p>{extras.currency.value}</p>
																</div>
																<div className=" grid grid-cols-2  gap-4">
																	<strong>{extras.dialingCode.title} :</strong>
																	<p>{extras.dialingCode.value}</p>
																</div>
															</div>
														);
													})}
												</div>
												<div className=" pb-8 lg:pb-0 | text-center lg:text-left">
													<Link
														to="/contact"
														className=" px-9 py-3 | text-ColorSecondary hover:text-White font-sans | border border-ColorSecondary rounded-full | hover:bg-ColorSecondary | duration-300"
													>
														Enquiry Now
													</Link>
												</div>
											</div>
											<img
												className=" flex-1 | w-full lg:w-[100px] h-full"
												src={destination.image}
												alt={destination.name}
											/>
											<Link
												to="/destinations"
												className="flex lg:hidden items-center | text-sm | group hover:text-ColorSecondary"
											>
												<Icon
													icon="eva:arrow-ios-back-outline"
													// color="white"
													height="20"
												/>
												Back to Destinations
											</Link>
										</div>
									</div>
								</div>
							</section>
							<section className=" md:mx-20 px-5 md:px-24 py-20 | text-center text-Brandwhite | md:border-x md:border-Brandwhite"></section>
						</>
					);
				})}
			</main>
			<Footer />
		</>
	);
}

export default Destination;
