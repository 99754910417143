import { React, useState, useEffect } from "react";

// import UI elements
import SeccondaryHeader from "../secondaryHeader";
import Footer from "../Footer";

// import assests
import TestimonialImg1 from "../../Assets/testimonials/testimonials-img-1.png";
import TestimonialImg2 from "../../Assets/testimonials/testimonials-img-2.png";
import TestimonialImg3 from "../../Assets/testimonials/testimonials-img-3.png";
import TestimonialImg4 from "../../Assets/testimonials/testimonials-img-4.png";
import TestimonialImg5 from "../../Assets/testimonials/testimonials-img-5.png";
import TestimonialImg6 from "../../Assets/testimonials/testimonials-img-6.png";
import TestimonialImg7 from "../../Assets/testimonials/testimonials-img-7.png";
import TestimonialImg8 from "../../Assets/testimonials/testimonials-img-8.jpeg";
import backgroundPattern from "../../Assets/background-pattern.png";

function Testimonials() {
	// This is page title
	const [title] = useState(
		"Our Clients Speak | Wonderland Treks & Tours Pvt. Ltd."
	);

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
	const testimonialsList = [
		{
			name: "Teri & Scot , United Kingdom",
			massage:
				"Wonderland Treks & Tours Pvt. Ltd. made my dream Himalayan adventure a reality! The attention to detail, knowledgeable guides, and seamless organization exceeded my expectations. I highly recommend their services for an unforgettable travel experience.",
		},
		{
			name: "Katrin & Group, Germany",
			massage:
				"I had an incredible journey with Wonderland Treks & Tours Pvt. Ltd. From exploring cultural gems to trekking through breathtaking landscapes, every moment was curated to perfection. Thanks to the team for creating memories that will last a lifetime.",
		},
		{
			name: "Susan & Jeff, USA",
			massage:
				"Wonderland Treks & Tours Pvt. Ltd. provided an exceptional travel experience. The professionalism and warmth of the team made my solo trip through India an absolute joy. I felt safe, well-informed, and immersed in the beauty of every destination.",
		},
		{
			name: "Thomas & Group, Germany",
			massage:
				"Kudos to Wonderland Treks & Tours Pvt. Ltd. for an amazing wildlife safari experience! The attention to conservation, knowledgeable guides, and luxurious accommodations made our journey through India's national parks truly unforgettable. A top-notch travel company!",
		},
		{
			name: "Elis & Barbara, Denmark",
			massage:
				"I can't thank Wonderland Treks & Tours Pvt. Ltd. enough for the fantastic family vacation we had in the Indian Himalayas. The itinerary was well-planned, and the team went above and beyond to accommodate our needs. A perfect blend of adventure and relaxation!",
		},
		{
			name: "Anat, Rami, Avi, Shoshi",
			massage:
				"Thank you so much dear man, nothing you did is self evident. We love you and appreciate you and wish for ourselves that we will travel with you again to other places and of course recommend you wholeheartedly to anyone who asks. We wish you success in your business and personal life. Lots of health and happiness to you and all your loved ones.",
		},
	];

	return (
		<div>
			<SeccondaryHeader />
			<div
				style={{ backgroundImage: `url(${backgroundPattern})` }} // Background pattern image
				className=" bg-ColorPrimary text-White"
			>
				<section>
					<div className=" md:mx-20 md:border-x md:border-White">
						<div className=" py-10 md:py-12 lg:py-14 px-5 md:px-24 | text-center | border-b">
							<h1 className=" mb-8">Our clients speak...</h1>
							<p>"Simply the best."</p>
						</div>
						{/* Testimonial 1 */}
						<div className=" grid md:grid-cols-2 lg:grid-cols-3 | border-b">
							<div>
								<img src={TestimonialImg1} alt="" />
							</div>
							<div className=" bg-ColorPrimary | p-10 | flex flex-col gap-8 justify-center | text-center | border-y md:border-y-0 md:border-l lg:border-x">
								<p>" {testimonialsList[0].massage} "</p>
								<h3>{testimonialsList[0].name}</h3>
							</div>
							<div>
								<img
									className=" md:hidden lg:block"
									src={TestimonialImg2}
									alt=""
								/>
							</div>
						</div>
						{/* Testimonial 2 */}
						<div className=" grid lg:grid-cols-2 | border-b">
							<div className=" bg-ColorPrimary | p-10 | flex flex-col gap-8 justify-center | text-center | border-b lg:border-r">
								<p>" {testimonialsList[1].massage} "</p>
								<h3>{testimonialsList[1].name}</h3>
							</div>
							<div>
								<img src={TestimonialImg5} alt="" />
							</div>
						</div>
						{/* Testimonial 3 */}
						<div className=" grid lg:grid-cols-2 | border-b">
							<div>
								<img src={TestimonialImg6} alt="" />
							</div>
							<div className=" bg-ColorPrimary | p-10 | flex flex-col gap-8 justify-center | text-center | border-t lg:border-t-0 lg:border-l">
								<p>" {testimonialsList[2].massage} "</p>
								<h3>{testimonialsList[2].name}</h3>
							</div>
						</div>
						{/* Testimonial 4 */}
						<div className=" grid md:grid-cols-2 lg:grid-cols-3 | border-b">
							<div>
								<img src={TestimonialImg3} alt="" />
							</div>
							<div className=" bg-ColorPrimary | p-10 | flex flex-col gap-8 justify-center | text-center | border-y md:border-y-0 md:border-l lg:border-x">
								<p>" {testimonialsList[3].massage} "</p>
								<h3>{testimonialsList[3].name}</h3>
							</div>
							<div>
								<img
									className=" md:hidden lg:block"
									src={TestimonialImg4}
									alt=""
								/>
							</div>
						</div>
						{/* Testimonial 5 */}
						<div className=" grid lg:grid-cols-2 | border-b">
							<div className=" bg-ColorPrimary | p-10 | flex flex-col gap-8 justify-center | text-center | border-b md:border-b-0 lg:border-r">
								<p>" {testimonialsList[4].massage} "</p>
								<h3>{testimonialsList[4].name}</h3>
							</div>
							<div>
								<img src={TestimonialImg7} alt="" />
							</div>
						</div>
						{/* Testimonial 6 */}
						<div className=" grid lg:grid-cols-2 | border-b">
							<div>
								<img src={TestimonialImg8} alt="" />
							</div>
							<div className=" bg-ColorPrimary | p-10 | flex flex-col gap-8 justify-center | text-center | border-t lg:border-t-0 lg:border-l">
								<p>" {testimonialsList[5].massage} "</p>
								<h3>{testimonialsList[5].name}</h3>
							</div>
						</div>
					</div>
				</section>
				<section className=" border-t border-White">
					<div className=" md:mx-20 px-5 md:px-24 py-20 | text-center text-Brandwhite | md:border-x md:border-White"></div>
				</section>
			</div>
			<Footer />
		</div>
	);
}

export default Testimonials;
