import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/pages/Home";
import Contact from "./components/pages/contact";
import Destinations from "./components/pages/destinations";
import Destination from "./components/destination";
import Overlay from "./components/pages/Overlay";
import Terms from "./components/pages/Terms";
import Testimonials from "./components/pages/testimonials";

function App() {
	return (
		<Router>
			<div className="App font-sans scrollStop">
				{/* Used "react-router-dom" lib to manage routes */}

				<Routes>
					{/* page overlay component */}
					<Route path="/" exact element={<Overlay />} />

					{/* Home page component */}
					<Route path="/home" element={<Home />} />

					{/* Contact page component */}
					<Route path="/contact" element={<Contact />} />

					{/* Destinations page Component */}
					<Route path="/destinations" exact element={<Destinations />} />
					{/* Single destination page component */}
					<Route path="/destinations/:id" element={<Destination />} />

					{/* Testimonials page component */}
					<Route path="/testimonials" element={<Testimonials />} />

					{/* Term's and conditions page Componet */}
					<Route path="/terms" element={<Terms />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
