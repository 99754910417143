import React from "react";

function Link({ text = "Link name", link }) {
	return (
		<>
			<a
				href={link}
				className=" | text-base md:text-base text-White hover:text-ColorSecondary"
			>
				{text}
			</a>
		</>
	);
}

export default Link;
