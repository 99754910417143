import { React, useEffect, useState } from "react";

// import assets
import AboutImage from "../../Assets/about-section-image.jpg";
import WhyUsImage from "../../Assets/why-us-image.jpg";
import HimachalTourism from "../../Assets/affiliations/himachal-tourism-white.png";
import MinistryOfTourism from "../../Assets/affiliations/Ministry_of_Tourism_India-white.png";
import Nidhi from "../../Assets/affiliations/nidhi-white.png";
import backgroundPattern from "../../Assets/background-pattern.png";

// import ui componentes
import HomeHeader from "../homeHeader";
import ImageSlider from "../ImageSlider";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const ourCoreValues = [
	{
		id: 1,
		title: "We are one team",
		discription:
			"Our working experience imbued us with the saying: if you want to go fast, go alone; if you want to go far, go together. At Wonderland Treks & Tours Pvt. Ltd., we are determined to create a tripod with listening – learning – respecting to grow together. We share the same vision and working goals. We harmonize on building a productive & happy team.",
	},
	{
		id: 2,
		title: "Serve with respect",
		discription:
			"We listen to our customers and always treat them how they want to be treated. As destination experts, we are committed to providing our customers with unique experiences at competitive prices, seamless customer service, and up-to-the-minute information. All customer feedback is analyzed to improve service quality on time.",
	},
	{
		id: 3,
		title: "Do the right things",
		discription:
			"We do the right things rather than just speak the right things. Our goal is building an ideal operating model, thereby bringing fairness and satisfaction to each part of the process. Everything we do benefits customers, partners, colleagues, and the organization. We show integrity in every action & never break the established commitments.",
	},
	{
		id: 4,
		title: "Our Success",
		discription:
			"We are building a healthy business, so profitability and growth are essential. Our mission is to create unique, personalized southeast Asia holidays for discerning travelers from around the world. Your satisfaction is the ultimate destination in our branding journey. Therefore, our success is measured by the index of happy customers, repeat customers, and referrals.",
	},
];

const WhyUs = [
	{
		title: "We are Locals ",
		discription:
			"If you are planning a trip with us., please be sure that the area and the itinerary had RECCE before by our team. Our travel expert can provide you with invaluable advices on the itinerary and can carve a beautiful tailor-made trip for you that suites your desires.",
	},
	{
		title: "Companions",
		discription:
			"Our personnel will be available around the clock to assist you throughout from your itinerary plannings to the end of the holidays. Whether it's a special meal request, an unusual excursion, or just some linguistic guidance. We are committed to be your best companions to make you holidays memorable.",
	},
	{
		title: "Meticulous",
		discription:
			"We will be your own travel concierge from the moment you contact us until the moment you say your goodbyes. Our excursions and trips are meticulously planned, with every detail hand-picked from among a plethora of hotels and service providers that share our standards for quality.",
	},
	{
		title: "Sustainability",
		discription:
			"It is our promise that we will ensure a respectful and careful approach to travel. Respecting and protecting the local communities, wildlife, their habitats, & the incredible heritage of the region for future generations to come must be part of all our visions.",
	},
	{
		title: "Responsible Travel",
		discription:
			"Our focus is to create journeys that are unique, dynamic and beneficial for all involved; the travelers and the local communities. We are passionate about responsible travel. Sharing this activity with everyone will guarantee that communities flourish and both cultural heritage and the environment are conserved in every location we visit. In addition, we participate in the charity's activities.",
	},
];

function Home() {
	// This is the page title
	const [title] = useState("Home | Wonderland Treks & Tours Pvt. Ltd.");

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
	return (
		<>
			{/* Header */}
			<HomeHeader />

			{/* Slider */}
			<section className="border-b border-White flex justify-center items-center">
				{/* Image slider component */}
				<ImageSlider />
			</section>

			{/* Abou Us */}
			<section
				id="about"
				style={{ backgroundImage: `url(${backgroundPattern})` }} //background pattern image
				className="about-us bg-ColorPrimary text-White bg-repeat font-serif"
			>
				<div className="md:mx-20 text-center text-Brandwhite md:border-x md:border-White">
					<h5 className="py-10 md:py-24 px-5 md:px-24 lg:px-40 | text-center md:leading-[2rem] lg:leading-[2rem]">
						Indian sub-continent is perhaps a dream destination and an ultimate
						travelers delight, containing some of the most spectacular sights,
						monuments and the richest culture in the world. Indian sub-continent
						not only attracts the monuments and the history lovers but also
						attract the adventure lovers. Those who choose Indian sub-continent
						as their travel destination for vacations, find all kinds of natural
						beauty for which India is envied and loved by other nations.
					</h5>

					<div className=" flex flex-col-reverse lg:flex-row lg:justify-center | px-5 pt-20 md:pt-0 md:px-24 | border-t border-White">
						<div className=" lg:flex-1 | mb-20 md:mb-20 lg:mb-0 lg:py-24 | text-left | lg:border-r md:border-White">
							<h2 className=" pt-8 mb-4 md:mb-5 | text-lg md:text-xl lg:text-3xl font-extrabold ">
								About Us
							</h2>
							<div className=" grid gap-8 | lg:pr-28 mb-8 md:mb-10 | text-sm md:text-base font-sans leading-normal text-justify">
								<p className="">
									Wonderland Treks & Tours Pvt. Ltd. is a company limited by
									shares, nestled in the foothills of western Himalayas in a
									beautiful & adventurous valley of Kullu. Located about 450 Kms
									north of New Delhi. Since its incorporation the company is
									committed to bring travelers the best in value and quality
									travel arrangements. Our company was founded by the travel
									enthusiasts who share a love for travel. After years of
									working in the travel industry, they decided to follow the
									passion and start own travel company. The promoters of the
									company have worked for various travel brands and have wealth
									of knowledge and experience in the travel industry. Our team
									is made up of passionate travellers who have a wealth of
									knowledge and experience in the travel industry. From our
									expert travel advisors to our friendly customer service team,
									we are all committed to making your travel experience
									unforgettable.
								</p>
								<p>
									We believe that travel should be sustainable and responsible,
									we are committed to minimizing our impact on the environment
									and supporting local communities where ever we go. We also
									believe in providing our customers with exceptional service
									every step of the way.
								</p>
							</div>
						</div>
						<div className=" h-full | lg:flex lg:flex-1 lg:justify-end lg:self-center | md:py-16 lg:py-16">
							<img
								className="  mb-10 md:mb-0 | lg:w-[80%] lg:h-auto"
								src={AboutImage}
								alt=""
							/>
						</div>
					</div>
				</div>

				{/* Our Core Values */}
				<section className=" border-t border-White">
					<div className=" px-5 md:mx-20 md:px-24 py-20 md:border-x ">
						<h2 className=" pb-6 | text-lg md:text-xl lg:text-3xl font-extrabold">
							Our core values
						</h2>
						<p className=" pb-12 | text-sm md:text-base leading-normal font-sans text-justify">
							We listen to our customers and always treat them how they want to
							be treated. As destination experts, we are committed to providing
							our customers with unique experiences at competitive prices,
							seamless customer service, and up-to-the-minute information. All
							customer feedback is analyzed to improve service quality on time.
						</p>
						<div className=" font-sans lg:px-2">
							<ol className=" grid lg:grid-cols-2 gap-10">
								{/* Render list of core values */}
								{ourCoreValues.map((values) => {
									return (
										<li className=" bg-ColorPrimary | flex | border border-White border-opacity-20 lg: p-6">
											<div>
												<h3>{values.title}</h3>
												<p className=" pt-2 | text-sm md:text-base font-light text-justify">
													{values.discription}
												</p>
											</div>
										</li>
									);
								})}

								{/* <li className=" flex gap-3 align-top">
                                <span className=" text-7xl font-bold">
                                    {" "}
                                    {ourCoreValues[0].id}{" "}
                                </span>
                                <div>
                                    <h3 className=" text-3xl">
                                        {" "}
                                        {ourCoreValues[0].title}{" "}
                                    </h3>
                                    <p className=" pt-10">
                                        {ourCoreValues[0].discription}
                                    </p>
                                </div>
                            </li> */}
							</ol>
						</div>
					</div>
				</section>
			</section>

			{/* Affiliations section */}
			<section
				style={{ backgroundImage: `url(${backgroundPattern})` }} //background pattern image
				className=" border-y border-White bg-ColorPrimary text-White font-serif"
			>
				<div className=" bg-ColorPrimary | px-5 md:mx-20 md:px-0 py-20 | md:border-x border-White">
					<h2 className=" mb-4 md:mb-5 | text-center md:text-xl lg:text-3xl font-extrabold">
						Affiliations
					</h2>
					<div className=" flex flex-col md:flex-row md:justify-around lg:justify-center gap-4 md:gap-20">
						<img
							className=" w-1/4 lg:w-28 h-auto | self-center | brightness-75"
							src={HimachalTourism}
							alt="Himachal Tourism"
						/>
						<img
							className=" w-1/4 lg:w-28 h-auto | self-center | brightness-75"
							src={MinistryOfTourism}
							alt="Ministry of Toursm"
						/>
						<img
							className=" w-1/4 lg:w-28 h-auto | self-center | brightness-75"
							src={Nidhi}
							alt="Nidhi"
						/>
					</div>
				</div>
			</section>

			{/* Why Us? */}
			<section
				style={{ backgroundImage: `url(${backgroundPattern})` }} //background pattern image
				className="why-us border-b border-White bg-ColorPrimary text-White font-serif">
				<div className=" px-5 md:mx-20 md:px-24 py-20 md:border-x ">
					<h2 className=" pb-6 | text-lg md:text-xl lg:text-3xl font-extrabold">
						Why Us?
					</h2>
					<p className=" pb-12 | text-sm md:text-base leading-normal font-sans text-justify">
						Wonderland Treks and Tours Pvt. Ltd. is organizing various tours in
						India, Nepal, Bhutan, Sri Lanka, Vietnam, Kyrgyzstan & Kazakhstan,
						organizing activities like Trekking, Camping, Climbing expeditions,
						Monument Tours, Historic Tours, Monastic Tours, Beach Holidays,
						Wildlife tours, Leisure tours, Religious Tours, Yoga & Meditation
						Tours. Our packages are tailor made, designed as per the choice and
						requirements of our guests. Please feel free to drop us your
						requirements and choices, we will come up with a designed itinerary
						specially tailored for you. Our mission is to be the leading and
						most trustworthy travel management company that sets the standard
						for professionalism, reliability and transparency to its customers.
					</p>
					<div className=" font-sans lg:px-2">
						<ol className=" grid lg:grid-cols-2 gap-10">
							{/* Render list of Why Us values */}
							{WhyUs.map((values) => {
								return (
									<li className=" bg-ColorPrimary | flex | border border-White border-opacity-20 lg: p-6">
										<div>
											<h3>{values.title}</h3>
											<p className=" pt-2 | text-sm md:text-base font-light text-justify">
												{values.discription}
											</p>
										</div>
									</li>
								);
							})}
						</ol>
					</div>
				</div>
				{/* <div className="md:mx-20 | border-t md:border-x border-White">
					<div className="">
						<div className=" bg-ColorPrimary | px-5 md:px-24 lg:flex lg:justify-center lg:items-center">
							<div className=" lg:flex lg:flex-1 lg:justify-start | lg:py-24 | md:py-16 mt-20 mb-10 md:mt-0 md:mb-0 | lg:border-r">
								<img className=" lg:w-[80%]" src={WhyUsImage} alt="" />
							</div>
							<div className=" md:flex md:flex-1 lg:justify-end | text-left text-White">
								<div className=" mb-20 md:mb-16 lg:mb-0 lg:w-[80%]">
									<h2 className=" mb-4 md:mb-5 | text-l md:text-xl lg:text-3xl font-extrabold">
										Why Us?
									</h2>
									<p className=" mb-8 md:mb-10 | text-sm md:text-base font-sans leading-normal text-justify">
										Wonderland Treks and Tours Pvt. Ltd. is organizing various
										tours in India, Nepal, Bhutan, Sri Lanka, Vietnam,
										Kyrgyzstan & Kazakhstan, organizing activities like
										Trekking, Camping, Climbing expeditions, Monument Tours,
										Historic Tours, Monastic Tours, Beach Holidays, Wildlife
										tours, Leisure tours, Religious Tours, Yoga & Meditation
										Tours. Our packages are tailor made, designed as per the
										choice and requirements of our guests. Please feel free to
										drop us your requirements and choices, we will come up with
										a designed itinerary specially tailored for you. Our mission
										is to be the leading and most trustworthy travel management
										company that sets the standard for professionalism,
										reliability and transparency to its customers.
									</p>
									<Link
										to="/contact"
										className=" px-9 py-3 | text-ColorSecondary hover:text-White font-sans | border border-ColorSecondary rounded-full | hover:bg-ColorSecondary | duration-300"
									>
										Enquiry Now
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</section>

			{/* CTA Section  */}
			<section
				style={{ backgroundImage: `url(${backgroundPattern})` }} //background pattern image
				className=" bg-ColorPrimary"
			>
				<div className=" md:mx-20 px-5 md:px-24 py-20 | text-center text-Brandwhite | md:border-x md:border-White"></div>
			</section>
			{/* Footer */}
			<Footer />
		</>
	);
}

export default Home;
