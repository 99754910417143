import { React, useEffect, useState } from "react";

// import assets
import Compass from "../../Assets/contact-image.jpg";
import backgroundPattern from "../../Assets/background-pattern.png";

// import UI components
import SeccondaryHeader from "../secondaryHeader";
import Footer from "../Footer";
import SecondaryButton from "../secondaryButton";
import { Link } from "react-router-dom";

// import icons
import { Icon } from "@iconify/react";

function Contact() {
	// This is page title
	const [title] = useState("Contact | Wonderland Treks & Tours Pvt. Ltd.");

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
	return (
		<>
			{/* Header components */}
			<SeccondaryHeader />

			<div
				style={{ backgroundImage: `url(${backgroundPattern})` }} // Background pattern image
				className="bg-ColorPrimary text-White bg-repeat"
			>
				{/* Address Section */}
				<section className="about-us font-serif">
					<div className="md:mx-20 text-center text-White md:border-x md:border-White">
						<h1 className="py-10 md:py-12 lg:py-14 px-5 md:px-24 | text-xl md:text-2xl lg:text-3xl font-extrabold">
							Contact Us
						</h1>

						<div className=" border-y">
							<div className=" px-5 md:px-20 lg:flex lg:justify-center lg:items-center mt-20 md:mt-0">
								<div className="flex flex-1 justify-center | md:pt-16 lg:py-9 lg:pr-14 | lg:border-r border-White">
									<img
										className=" h-auto md:h-auto lg:h-auto md:w-[500px] lg:w-[500px]"
										title="Embeded google maps"
										src={Compass}
										width="400"
										height="600"
										alt="compass on a map"
									/>
								</div>
								<div className=" flex-1 md:flex lg:justify-end | py-10 md:py-12 lg:py-8 | h-full | text-White text-left font-sans | leading-normal">
									<div className=" lg:w-[80%]">
										<div className=" grid gap-6 md:gap-5 lg:gap-7">
											{/* Address */}
											<div className=" grid gap-2 | text-base md:text-base">
												<h3 className=" font-bold">Address :</h3>
												<p>Wonderland Treks & Tours Pvt. Ltd.</p>
												<p>Bloomfields, Village Bashing, P.O Babeli</p>
												<p>Kullu (H.P.) 175138, India</p>
											</div>
											{/* Email */}
											<div className=" grid gap-2 | text-base md:text-base">
												<h3 className=" font-bold">Email :</h3>
												<Link
													className=" hover:underline"
													to="mailto:adventureatwonderland@gmail.com"
												>
													adventureatwonderland@gmail.com
												</Link>
											</div>
											{/* Phone */}
											<div className=" grid gap-2 | text-base md:text-base">
												<h3 className=" font-bold">Phone :</h3>
												<Link
													className=" hover:underline"
													to="tel:+919816005977"
												>
													+91 98160 05977
												</Link>
												<span className=" flex gap-4 | pl-2 pt-3">
													<Link
														to="https://wa.me/+919816005977"
														target=" _blank"
													>
														<Icon
															icon="logos:whatsapp-icon"
															width="24"
															height="24"
														/>
													</Link>
													<Link
														to="https://telegram.me/+919816005977"
														target="_blank"
													>
														<Icon
															icon="logos:telegram"
															width="24"
															height="24"
														/>
													</Link>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Get In Touch */}
				<section
					id="more"
					className=" bg-ColorPrimary | text-center text-White md:mx-20 px-5 md:px-24 py-20 md:border-x md:border-White"
				>
					<h2 className=" text-xl md:text-2xl lg:text-4xl font-extrabold font-serif">
						Get in touch
					</h2>

					{/* Contact From */}
					<form
						className="flex flex-col gap-4 md:gap-8 pt-10 md:pt-16 px-5 lg:px-20"
						action="https://mail.google.com/mail/?view=cm&to=adventureatwonderland@gmail.com&su=New%20Wonderland%20website%20enquiry%20submit"
						target=" _blank"
						method="POST"
					>
						<div className=" flex gap-4 md:gap-8 justify-center w-full text-left">
							{/* col-1 */}
							<div className="flex flex-col flex-1 gap-4 md:gap-8">
								<div>
									<label for="name" className="text-base md:text-base">
										First Name*:
									</label>
									<input
										id="name"
										name="first-name"
										type="text"
										required
										className="w-full mt-4 border-b brightness-75 focus:brightness-100 bg-transparent focus-visible:outline-none"
									/>
								</div>
								<div>
									<label for="email" className="text-base md:text-base">
										Email*:
									</label>
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										required
										className="w-full mt-4 border-b brightness-75 focus:brightness-100 bg-transparent focus-visible:outline-none"
									/>
								</div>
							</div>

							{/* col-2 */}
							<div className="flex flex-col flex-1 gap-4 md:gap-8">
								<div>
									<label for="company" className="text-base md:text-base">
										Company:
									</label>
									<input
										id="company"
										name="company"
										type="text"
										className="w-full mt-4 border-b brightness-75 focus:brightness-100 bg-transparent focus-visible:outline-none"
									/>
								</div>
								<div>
									<label for="company" className="text-base md:text-base">
										Phone*:
									</label>
									<input
										id="phone"
										name="phone"
										type="phone"
										required
										className="w-full mt-4 border-b brightness-75 focus:brightness-100 bg-transparent focus-visible:outline-none"
									/>
								</div>
							</div>
						</div>
						<div className="text-left pb-10 lg:pb-16">
							{/* Message field */}
							<label for="message" className=" text-base">
								Message
							</label>
							<input
								id="message"
								name="message"
								type="textfield"
								className="w-full mt-10 border-b brightness-75 focus:brightness-100 bg-transparent focus-visible:outline-none"
							/>
						</div>
						{/* Submit button */}
						<SecondaryButton text="Submit" />
					</form>
				</section>
				<section className=" border-t border-White">
					<div className=" md:mx-20 px-5 md:px-24 py-20 | text-center text-Brandwhite | md:border-x md:border-White"></div>
				</section>
			</div>

			{/* CTA component section */}

			{/* Footer component */}
			<Footer />
		</>
	);
}

export default Contact;
