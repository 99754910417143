import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

// import assets
import DestinationIndia from "../../Assets/destinations/destination-india.jpg";
import DestinationButan from "../../Assets/destinations/destination-bhutan.jpg";
import DestinationNepal from "../../Assets/destinations/destination-nepal.jpg";
import DestinationVietnam from "../../Assets/destinations/destination-vietnam.png";
import backgroundPattern from "../../Assets/background-pattern.png";

// import UI components
import SeccondaryHeader from "../secondaryHeader";
import Footer from "../Footer";

function Destinations() {
	// This is page title
	const [title] = useState("Destinations | Wonderland Treks & Tours Pvt. Ltd.");

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);

	const destinationsList = [
		{
			name: "India",
			image: `${DestinationIndia}`,
			exerpt:
				"India, a diverse and enchanting land, beckons travellers with its rich tapestry of culture, history, and natural beauty. From the majestic Himalayan peaks to the serene backwaters of Kerala, India offers a multitude of experiences. ",
			discription: {
				p1: "India, a diverse and enchanting land, beckons travellers with its rich tapestry of culture, history, and natural beauty. From the majestic Himalayan peaks to the serene backwaters of Kerala, India offers a multitude of experiences. Explore the iconic Taj Mahal, marvel at ancient temples, Savor delectable cuisine, and partake in vibrant festivals. Immerse yourself in the bustling markets of Delhi, the tranquil beaches of Goa, or the mystical deserts of Rajasthan. With its warm hospitality and a kaleidoscope of landscapes, India is a captivating destination that leaves an indelible mark on every traveller’s heart, promising an unforgettable journey of discovery. Wonderland Treks & Tours Pvt. Ltd. is present in India and plans custom trips there that last between 7 and 25 days.",
			},
			extras: {
				officialName: { title: "Official Name", value: "Republic of india" },
				population: { title: "Population", value: "1.39 billion (Approx)" },
				capital: { title: "Capital", value: "Delhi" },
				people: {
					title: "People",
					value:
						"Approximately 80% of the citizens of India are Hindus, the country has a substantial population of Muslims, Christians, Sikhs, Buddhists, Jains, Parsis and adherents of tribal faiths",
				},
				language: { title: "Language", value: "Hindi & English" },
				currency: { title: "Currency", value: "Indian rupee (INR)" },
				dialingCode: { title: "international Dialing Code", value: "+91" },
			},
		},
		{
			name: "Bhutan",
			image: `${DestinationButan}`,
			exerpt:
				"Bhutan, nestled in the eastern Himalayas, is a hidden gem for travelers seeking a unique and pristine destination. Known for its stunning landscapes, Bhutan offers lush green valleys, snow-capped peaks, and serene monasteries.",
			discription: {
				p1: "Bhutan, nestled in the eastern Himalayas, is a hidden gem for travelers seeking a unique and pristine destination. Known for its stunning landscapes, Bhutan offers lush green valleys, snow-capped peaks, and serene monasteries. The country's commitment to Gross National Happiness ensures a tranquil and sustainable experience for visitors. Explore ancient monasteries like Paro Taktsang, embark on treks through pristine forests, and immerse yourself in Bhutanese culture during colorful festivals. With a limited number of tourists allowed each year, Bhutan offers an exclusive and authentic travel experience, making it a must-visit destination for those seeking natural beauty and cultural richness. Wonderland Treks & Tours Pvt. Ltd. is present in Bhutan and plans custom trips there that last between 5 and 17 days.",
			},
			extras: {
				officialName: {
					title: "Official Name",
					value: "The Kingdom of Bhutan",
				},
				population: { title: "Population", value: "7.77 Lakhs (Approx)" },
				capital: { title: "Capital", value: "Thimpu" },
				people: {
					title: "People",
					value:
						"Ngalong (also known as Bhote), Sharchop, Kheng, and Nepali-speakers",
				},
				language: { title: "Language", value: "Dzongkha (Bhutanese)" },
				currency: { title: "Currency", value: "Ngultrum" },
				dialingCode: { title: "international Dialing Code", value: "+975" },
			},
		},
		{
			name: "Nepal",
			image: `${DestinationNepal}`,
			exerpt:
				"Nepal, nestled in the lap of the Himalayas, is a paradise for adventurers and nature enthusiasts. This small but incredibly diverse country offers a breathtaking range of experiences, from the towering peaks of Mount Everest to the lush jungles of Chitwan National Park.",
			discription: {
				p1: "Nepal, nestled in the lap of the Himalayas, is a paradise for adventurers and nature enthusiasts. This small but incredibly diverse country offers a breathtaking range of experiences, from the towering peaks of Mount Everest to the lush jungles of Chitwan National Park. Trekking is a highlight, with the Annapurna and Langtang regions offering stunning trails. The rich cultural heritage, seen in ancient temples and monasteries, adds depth to the experience. The warmth of the Nepalese people and their delicious cuisine further enrich the journey. Whether you seek high-altitude thrills or spiritual solace, Nepal's tourism promises unforgettable memories.",
				p2: "Wonderland Treks & Tours Pvt. Ltd. has its presence in Nepal and organise tailor-made trips ranging from 6 to 19 days in Nepal.",
			},
			extras: {
				officialName: {
					title: "Official Name",
					value: "Federal Democratic Republic of Nepal",
				},
				population: { title: "Population", value: "30.8 Million (Approx)" },
				capital: { title: "Capital", value: "Kathmandu" },
				people: {
					title: "People",
					value:
						"The Tamang, Rai, Limbu, Bhutia (including the Sherpa), and  Sunwar—live in the north and east, while the Magar and Gurung inhabit west-central Nepal",
				},
				language: { title: "Language", value: "Nepali" },
				currency: { title: "Currency", value: "Nepalese Rupee" },
				dialingCode: { title: "International Dialing Code", value: "+977" },
			},
		},
		{
			name: "Vietnam",
			image: `${DestinationVietnam}`,
			exerpt:
				"With over 3000 kilometres of coastline, pristine beaches, dynamic growing cities, mountainous highlands, and diverse cultural and historic sites, Vietnam's tourism industry continues to rank amongst the fastest-growing tourist destinations in the world.",
			discription: {
				p1: "With over 3000 kilometres of coastline, pristine beaches, dynamic growing cities, mountainous highlands, and diverse cultural and historic sites, Vietnam's tourism industry continues to rank amongst the fastest-growing tourist destinations in the world.",
				p2: "Tourism is important in Vietnam. For backpackers, culture and nature lovers, beach-lovers, military soldiers and veterans, Vietnam has become a new tourist destination in Southeast Asia. We offer tours to ethnic minority groups, walking and bicycle tours, photography tours, kayak trips and multi-country trips in particular with neighbouring Cambodia, Laos and Thailand.",
				p3: "Wonderland Treks & Tours Pvt. Ltd. has its presence in Vietnam and organise tailor-made trips ranging from 7 to 21 days in Vietnam.",
			},
			extras: {
				officialName: {
					title: "Offical Name",
					value: "The Socialist Republic of Vietnam",
				},
				population: {
					title: "Population",
					value: "10 Million (Approx)",
				},
				capital: {
					title: "Capital",
					value: "Hanoi",
				},
				people: {
					title: "People",
					value:
						"Viet (Kinh), 53 different ethnic minorities including Muong, Tay, Khmer, Mong.",
				},
				language: {
					title: "Language",
					value: "Vietnamese",
				},
				currency: {
					title: "Currency",
					value: "Vietnam dong (VND)",
				},
				dialingCode: {
					title: "International Dialing Code",
					value: "+84",
				},
			},
		},
	];

	return (
		<>
			{/* Header components */}
			<SeccondaryHeader />

			<div
				style={{ backgroundImage: `url(${backgroundPattern})` }} // Background pattern image
				className="bg-ColorPrimary text-White bg-repeat"
			>
				{/* Destinations Section */}
				<section className=" font-serif">
					<div className="md:mx-20 text-center text-Brandwhite md:border-x md:border-Brandwhite">
						<h1 className="py-10 md:py-12 lg:py-14 px-5 md:px-24 | text-xl md:text-2xl lg:text-3xl font-extrabold">
							Destinations
						</h1>

						<div className=" grid lg:grid-cols-2 md:divide-y">
							{/* Render list of destination*/}
							{destinationsList.map((destination) => {
								return (
									<div className="px-10 md:px-16 lg:px-24 py-10 md:py-16 lg:py-24 | first-of-type:border-y lg:first-of-type:border-t even:border-y lg:even:border-l | text-left">
										<Link
											to={`/destinations/${destination.name.toLocaleLowerCase()}`}
											state={destination}
										>
											<img src={destination.image} alt={destination.name} />
										</Link>

										<div className=" mt-10 md:mt-8">
											<h2 className=" font-extrabold">
												<Link
													className=" text-lg md:text-2xl lg:text-3xl | hover:text-ColorSecondary"
													to={`/destinations/${destination.name.toLocaleLowerCase()}`}
													state={destination}
												>
													{destination.name}
												</Link>
											</h2>
											<p className=" py-4 | text-sm md:text-base font-sans leading-normal text-justify">
												{destination.exerpt}
											</p>
											<Link
												className=" flex items-center | w-auto | pt-4 hover:text-ColorSecondary"
												to={`/destinations/${destination.name.toLocaleLowerCase()}`}
												state={destination}
											>
												<p className=" hover:mr-1 | text-sm md:text-base | duration-500">
													Read more
												</p>
												<Icon
													className=" mt-[4px]"
													icon="eva:arrow-ios-forward-fill"
												/>
											</Link>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>
				<section className=" border-t border-White">
					<div className=" md:mx-20 px-5 md:px-24 py-20 | text-center text-Brandwhite | md:border-x md:border-White"></div>
				</section>
			</div>

			{/* CTA component section */}

			{/* Footer component */}
			<Footer />
		</>
	);
}

export default Destinations;
