import React from "react";

function SecondaryButton({ text = "Submit" }) {
	return (
		<div>
			<button className=" w-36 md:w-32 lg:w-40 h-10 | border-2 border-White rounded-full | text-base md:text-lg text-White hover:text-ColorPrimary | hover:bg-White | duration-300 | font-sans">
				{text}
			</button>
		</div>
	);
}

export default SecondaryButton;
