import React from "react";

import { Link } from "react-router-dom";

import Links from "./Links";

function Footer() {
	return (
		<div className=" md:px-20 | bg-ColorPrimary | border-t border-White">
			<footer className=" flex flex-col lg:flex-row md:justify-center gap-x-5 gap-y-3 | py-4 px-5 md:py-10 | font-medium text-White text-opacity-70 | md:border-x border-White">
				<ul className="flex justify-center flex-wrap lg:flex-nowrap gap-y-1 gap-x-5 text-center">
					<li>
						<Link to="/home">
							<Links text="Home" />
						</Link>
					</li>
					<li>
						<Link to="/destinations">
							<Links text="Destinations" />
						</Link>
					</li>
					{/* <li>
                        <Link to="/privacy-policy">
                            <Links text="Privacy Policy" />
                        </Link>
                    </li> */}
					<li>
						<Link to="/testimonials">
							<Links text="Testimonials" />
						</Link>
					</li>
					<li>
						<Link to="/contact">
							<Links text="Contact Us" />
						</Link>
					</li>
					<li>
						<Link to="/terms">
							<Links text="T&C" />
						</Link>
					</li>
				</ul>
				<p className=" text-center">
					© {new Date().getFullYear()}{" "}
					<span className=" text-secondary">
						Wonderland Treks & Tours Pvt. Ltd.
					</span>
				</p>
			</footer>
		</div>
	);
}

export default Footer;
