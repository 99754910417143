// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import ImageSlider1 from "../Assets/Slider/Hero-slider-1.jpg";
import ImageSlider2 from "../Assets/Slider/Hero-slider-2.jpg";
import ImageSlider3 from "../Assets/Slider/Hero-slider-3.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

export default function App() {
	return (
		<>
			<Swiper
				effect={"fade"}
				centeredSlides={true}
				loop={true}
				autoplay={{
					delay: 15000,
					disableOnInteraction: false,
				}}
				navigation={true}
				pagination={{
					clickable: false,
				}}
				modules={[EffectFade, Autoplay, Pagination, Navigation]}
				className="mySwiper mix-blend-multiply"
			>
				<SwiperSlide>
					<img className=" w-full" src={ImageSlider1} alt="" />
				</SwiperSlide>
				<SwiperSlide>
					<img className=" w-full" src={ImageSlider2} alt="" />
				</SwiperSlide>
				<SwiperSlide>
					<img className=" w-full" src={ImageSlider3} alt="" />
				</SwiperSlide>
			</Swiper>
		</>
	);
}
