import React, { useRef } from "react";

import { Link } from "react-router-dom";

import Logo from "../Wonderland India logo.png";
import Boat from "../Assets/boat.jpg";

import MenuLink from "./menuLink";

function HomeHeader() {
	const MenuRef = useRef(null);

	const toggleMenuState = () => {
		MenuRef.current.classList.toggle("hidden");
	};

	return (
		<header>
			<nav className="flex justify-between absolute z-10 w-full h-16 lg:h-20 border-b border-White">
				<div className="flex">
					{/* Hamburger menu icon */}
					<button onClick={toggleMenuState}>
						<svg
							className=" md:w-[81px] h-full self-center px-5 py-6 border-r border-White stroke-White hover:stroke-ColorPrimary hover:bg-White duration-200"
							viewBox="0 0 31 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.37866 1H29.8058M1.37866 10H29.8058M1.37866 19H13.8156"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>

					{/* Logo */}
					<Link to="/home" className=" ml-10 md:ml-5 self-center">
						<img src={Logo} className=" w-60" alt="Logo" />
					</Link>
				</div>

				{/* Main menu links */}
				<div className="hidden md:block md:self-center md:pr-20 md:h-full">
					<ul className=" h-full flex gap-0">
						<li className="h-full border-x border-White">
							<Link to="/contact">
								<MenuLink text="Contact Us" />
							</Link>
						</li>
					</ul>
				</div>
			</nav>

			{/* Hidden menu */}
			<div ref={MenuRef} className=" hidden">
				<div className=" absolute w-screen pt-32 bg-White z-20 bg-opacity-95 text-ColorPrimary font-serif">
					<div className=" w-[60%] h-screen mx-auto">
						<div className=" flex justify-between">
							<Link to="/home" onClick={toggleMenuState}>
								<img className=" w-44 md:w-60" src={Logo} alt="" />
							</Link>
							<button onClick={toggleMenuState}>
								<svg
									className=" stroke-ColorSecondary"
									xmlns="http://www.w3.org/2000/svg"
									width="60"
									height="60"
									viewBox="0 0 40 40"
									fill="none"
								>
									<path
										d="M29.5238 29.5238L10.4762 10.4762M29.5238 10.4762L10.4762 29.5238"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</button>
						</div>
						<img
							className=" hidden lg:block w-[300px] | absolute right-[500px] top-20 |"
							src={Boat}
							alt=""
						/>
						<hr className=" text-ColorSecondary" />
						<ul className=" flex flex-col gap-5 | py-3 md:py-7 | text-sm md:text-base">
							<li className=" hover:text-ColorSecondary">
								<Link to="/home" onClick={toggleMenuState}>
									Home
								</Link>
							</li>
							<li className=" hover:text-ColorSecondary">
								<Link to="/destinations">Destinations</Link>
							</li>
							<li className=" hover:text-ColorSecondary">
								<Link to="/terms">Terms and Conditions</Link>
							</li>
							<li className=" hover:text-ColorSecondary">
								<Link to="/testimonials">Our Clients Speak</Link>
							</li>
							<li className=" hover:text-ColorSecondary">
								<Link to="/contact">Contact Us</Link>
							</li>
						</ul>
						<hr className=" text-ColorSecondary" />
					</div>
				</div>
			</div>
		</header>
	);
}

export default HomeHeader;
