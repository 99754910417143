import React, { Fragment } from "react";

function MenuLink({ text = "LinkText" }) {
	return (
		<>
			<p className=" flex items-center justify-center | h-full md:w-32 lg:w-40 | text-base md:text-base  text-White hover:text-ColorPrimary hover:bg-White | duration-300">
				{text}
			</p>
		</>
	);
}

export default MenuLink;
