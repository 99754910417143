import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import BgSlider from "../bgSlider";

import Logo from "../../Wonderland India logo.png";

function Overlay() {
	// This is the page title
	const [title] = useState("Home | Wonderland Treks & Tours Pvt. Ltd.");

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
	return (
		<>
			<div className=" absolute z-20 flex flex-col justify-center w-screen h-screen bg-White bg-opacity-60">
				<img className=" w-10/12 md:w-2/5 mx-auto" src={Logo} alt="" />
				<Link to="/home" className="mx-auto">
					<button className=" bg-ColorPrimary hover:bg-opacity-95 text-White rounded-full w-40 md:w-60  p-2 md:p-4 uppercase text-base md:text-lg">
						Explore Now!
					</button>
				</Link>
			</div>
			<div>
				<BgSlider />
			</div>
		</>
	);
}

export default Overlay;
